import React, { useEffect } from 'react';

import { useFacebookLandingText } from '@src/hooks/directusHooks/useFacebookLandingText';
import SocialNetworkV2FreePage from '@src/components/social-network-free-page/social-network-v2';
import couponCodeService from '@src/utils/couponCodeService';

// START: Vodafone redirect hack
import { useQueryParamValue, useAllQueryParams } from '@hooks/useQueryParams';
import { navigate } from 'gatsby';
// END: Vodafone redirect hack

const Lp3 = () => {
  const { setOffer } = couponCodeService();
  const { landing, home } = useFacebookLandingText();
  const text = {
    ...landing,
    ...home,
    hero_text: landing.hero_description,
    hero_focus: landing.hero_focus_text,
    hero_focus_price: 'FREE',
    how_it_work_header: landing.how_it_work_title,
    how_it_works_items_list: landing.how_it_works_items,
    sticky_banner_text: 'Take Quiz & Get a 2 Week Free Trial',
  };

  const sourceIsVodafone = useQueryParamValue('utm_source') == "vodafone"; // Vodafone redirect

  

  useEffect(() => {
    if(!sourceIsVodafone) {
      setOffer('4pp', 'freeTrial');
    }
  }, []);
  
  if (sourceIsVodafone) {
    const rediectPath = "/lp/3-70?" + useAllQueryParams();
    navigate(rediectPath);
    return null;
  } else {
    return <SocialNetworkV2FreePage text={text} noindex={true} />;
  }
};

export default Lp3;
